import { AppBar, Tab } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import variables from '../../utils/variables';
import { withRouter } from 'react-router';
import Icon from '../../components/Icon';
import { hideSideBar } from '../../actions/navigationBar';
import RequestsTab from './RequestsTab';

class NavTabs extends Component {
    constructor (props) {
        super(props);
        this.state = {
            value: 'dashboard',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubMenu = this.handleSubMenu.bind(this);
    }

    componentDidMount () {
        const route = this.props.location.pathname && this.props.location.pathname.split('/') &&
            this.props.location.pathname.split('/')[1];

        if (this.state.value !== route) {
            this.setState({
                value: route,
            });
        }
    }

    componentDidUpdate (pp, ps, ss) {
        if (pp.location.pathname !== this.props.location.pathname) {
            const value = this.props.location.pathname.split('/')[1];

            if (value !== this.state.value) {
                this.setState({
                    value: value,
                });
            }
        }
    }

    handleChange (newValue) {
        if (newValue === this.state.value) {
            return;
        }
        if (this.props.show) {
            this.props.handleClose();
        }

        this.props.history.push('/' + newValue);
        this.setState({
            value: newValue,
        });
    }

    handleSubMenu (newValue, link) {
        if (this.props.show) {
            this.props.handleClose();
        }

        this.props.history.push(`/${newValue}/${link}`);
        this.setState({
            value: newValue,
        });
    }

    render () {
        const a11yProps = (index) => {
            return {
                id: `simple-tab-${index}`,
                'aria-controls': `simple-tabpanel-${index}`,
            };
        };

        const { value } = this.state;
        const { lang } = this.props;

        const dashBoardClass = value === 'dashboard' ? 'active_tab' : '';
        const dealsClass = value === 'deals' ? 'active_tab' : '';
        const nextVaultClass = value === 'next-vault' ? 'active_tab' : '';
        const usersClass = value === 'users' ? 'active_tab' : '';
        const tippingClass = value === 'tipping' ? 'active_tab' : '';

        return (
            <AppBar className="horizontal_tabs" position="static">
                <div className="tabs_content">
                    <Tab
                        className={`tab ${dashBoardClass}`}
                        icon={<Icon className="dashboard" icon="dashboard"/>}
                        label={variables[lang].dashboard}
                        value="overview"
                        onClick={() => this.handleChange('dashboard')}
                        {...a11yProps(0)} />
                    <Tab
                        className={`tab ${dealsClass}`}
                        icon={<Icon className="deals" icon="deals"/>}
                        label={variables[lang].deals}
                        value="overview"
                        onClick={() => this.handleChange('deals')}/>
                    <RequestsTab
                        a11yProps={a11yProps}
                        handleChange={this.handleSubMenu}
                        lang={lang}
                        value={value}/>
                    <Tab
                        className={`tab ${nextVaultClass}`}
                        icon={<Icon className="next-vault" icon="next-vault"/>}
                        label={variables[lang].nextVault}
                        value="NextVault"
                        onClick={() => this.handleChange('next-vault')}/>
                    <Tab
                        className={`tab ${usersClass}`}
                        icon={<Icon className="users" icon="users"/>}
                        label={variables[lang].users}
                        value="users"
                        onClick={() => this.handleChange('users')}
                        {...a11yProps(2)} />
                    <Tab
                        className={`tab ${tippingClass}`}
                        icon={<Icon className="tipping" icon="tipping"/>}
                        label={variables[lang].tipping}
                        value="tipping"
                        onClick={() => this.handleChange('tipping')}
                        {...a11yProps(3)} />
                    <Tab
                        className={'tab ' + (this.state.value === 'referrals' ? 'active_tab' : '')}
                        icon={<Icon className="referrals" icon="referrals"/>}
                        label={variables[this.props.lang].referrals}
                        value="referrals"
                        onClick={() => this.handleChange('referrals')}
                        {...a11yProps(4)} />
                    <Tab
                        className={'tab ' + (this.state.value === 'tasks' ? 'active_tab' : '')}
                        icon={<Icon className="dashboard" icon="dashboard"/>}
                        label="Tasks"
                        value="tasks"
                        onClick={() => this.handleChange('tasks')}
                        {...a11yProps(5)} />
                    <Tab
                        className={'tab ' + (this.state.value === 'forums' ? 'active_tab' : '')}
                        icon={<Icon className="forums" icon="forums"/>}
                        label="Forums"
                        value="forums"
                        onClick={() => this.handleChange('forums')}
                        {...a11yProps(5)} />
                </div>
            </AppBar>
        );
    }
}

NavTabs.propTypes = {
    handleClose: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    lang: PropTypes.string.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    show: PropTypes.bool.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        show: state.navigationBar.show,
    };
};

const actionToProps = {
    handleClose: hideSideBar,
};

export default withRouter(connect(stateToProps, actionToProps)(NavTabs));
