import React from 'react';
import * as PropTypes from 'prop-types';
import {
    Button as MaterialButton,
    createTheme,
    ThemeProvider,
    makeStyles,
} from '@material-ui/core';

const Button = (props) => {
    const {
        onClick,
        children,
        className,
        color,
        disable,
        variant,
        type,
        size,
    } = props;
    let buttonSizeClass = '';

    const useStyles = makeStyles(() => ({
        primaryButton: {
            '&:hover': {
                backgroundColor: 'transparent',
                border: '2px solid #858787',
            },
            '&:disabled': {
                backgroundColor: '#EF0048',
                color: '#FFF',
                opacity: '0.5',
            },
        },
        secondaryButton: {
            '&:hover': {
                backgroundColor: 'transparent',
                border: '2px solid #858787',
            },
            '&:disabled': {
                backgroundColor: '#1739A4',
                color: '#FFF',
                opacity: '0.5',
            },
        },
        'slim-lg': {
            padding: '8px 30px',
        },
        sm: {
            padding: '10px 13px',
        },
        md: {
            padding: '13px 20px',
        },
        lg: {
            padding: '13px 30px',
        },
    }));

    const theme = createTheme({
        palette: {
            primary: {
                main: '#EF0048',
                contrastTextColor: '#FFF',
            },
            secondary: {
                main: '#1739A4',
                contrastTextColor: '#FFF',
            },
        },
    });

    const classes = useStyles();
    if (size) {
        switch (size) {
        case 'sm':
            buttonSizeClass += classes.sm;
            break;
        case 'md':
            buttonSizeClass += classes.md;
            break;
        case 'lg':
            buttonSizeClass += classes.lg;
            break;
        case 'slim-lg':
            buttonSizeClass += classes['slim-lg'];
            break;
        default:
            return '';
        }
    }
    return (
        <ThemeProvider theme={theme}>
            <MaterialButton
                className={`${className} ${buttonSizeClass} ${color === 'primary' ? classes.primaryButton : classes.secondaryButton}`}
                color={color ? color : 'primary'} // eslint-disable-line no-unneeded-ternary
                disabled={disable}
                type={type}
                variant={variant || 'contained'}
                onClick={onClick}
            >
                { children }
            </MaterialButton>
        </ThemeProvider>
    );
};

Button.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]).isRequired,
    className: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    color: PropTypes.string,
    disable: PropTypes.bool,
    size: PropTypes.string,
    type: PropTypes.string,
    variant: PropTypes.string,
};

export default Button;
