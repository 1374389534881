import { combineReducers } from 'redux';
import {
    FETCH_NEXT_VAULT_IN_PROGRESS,
    FETCH_NEXT_VAULT_SUCCESS,
    FETCH_NEXT_VAULT_ERROR,
    ADD_NEXT_VAULT_IN_PROGRESS,
    ADD_NEXT_VAULT_SUCCESS,
    ADD_NEXT_VAULT_ERROR,
    FETCH_NEXT_VAULT_DETAIL_IN_PROGRESS,
    FETCH_NEXT_VAULT_DETAIL_SUCCESS,
    FETCH_NEXT_VAULT_DETAIL_ERROR,
    ADD_NEXT_VAULT_LOG_IN_PROGRESS,
    ADD_NEXT_VAULT_LOG_SUCCESS,
    ADD_NEXT_VAULT_LOG_ERROR,
    FETCH_NEXT_VAULT_LOG_IN_PROGRESS,
    FETCH_NEXT_VAULT_LOG_SUCCESS,
    FETCH_NEXT_VAULT_LOG_ERROR,
} from '../constants/nextVault';

const nextVaultList = (state = {
    inProgress: false,
    list: [],
    count: 0,
}, action) => {
    switch (action.type) {
    case FETCH_NEXT_VAULT_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_NEXT_VAULT_SUCCESS:
        return {
            inProgress: false,
            list: action.message.nextVaultData,
            count: action.message.count,
        };
    case FETCH_NEXT_VAULT_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const nextVaultDetails = (state = {
    inProgress: false,
    result: {},
}, action) => {
    switch (action.type) {
    case FETCH_NEXT_VAULT_DETAIL_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_NEXT_VAULT_DETAIL_SUCCESS:
        return {
            inProgress: false,
            result: action.message,
        };
    case FETCH_NEXT_VAULT_DETAIL_ERROR:
        return {
            ...state,
            message: action.message,
            inProgress: false,
        };
    default:
        return state;
    }
};

const addNextVault = (state = {
    inProgress: false,
    message: '',
}, action) => {
    switch (action.type) {
    case ADD_NEXT_VAULT_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ADD_NEXT_VAULT_SUCCESS:
        return {
            inProgress: false,
            message: action.message,
        };
    case ADD_NEXT_VAULT_ERROR:
        return {
            ...state,
            inProgress: false,
            message: action.message,
        };
    default:
        return state;
    }
};

const addNextLogVault = (state = {
    inProgress: false,
    message: '',
}, action) => {
    switch (action.type) {
    case ADD_NEXT_VAULT_LOG_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ADD_NEXT_VAULT_LOG_SUCCESS:
        return {
            inProgress: false,
            message: action.message,
        };
    case ADD_NEXT_VAULT_LOG_ERROR:
        return {
            ...state,
            inProgress: false,
            message: action.message,
        };
    default:
        return state;
    }
};

const nextVaultLogs = (state = {
    inProgress: false,
    list: [],
    count: 0,
}, action) => {
    switch (action.type) {
    case FETCH_NEXT_VAULT_LOG_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_NEXT_VAULT_LOG_SUCCESS:
        return {
            inProgress: false,
            list: action.message.list,
            count: action.message.count,
        };
    case FETCH_NEXT_VAULT_LOG_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    nextVaultList,
    addNextVault,
    nextVaultDetails,
    addNextLogVault,
    nextVaultLogs,
});
