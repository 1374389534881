const variables = {
    en: {
        actions: 'Actions',
        ex_network: 'Exnetwork',
        sign_in: 'Sign In',
        sign_in_text: 'Please fill in the information below',
        password: 'Enter your password',
        password_label: 'Password',
        email_label: 'Email Address',
        email: 'Enter your email address',
        remember_me: 'Remember me',
        forgot_password: 'Forgot password',
        verify_your_email: 'Verify Your Email',
        forgot_your_password: 'Forgot Your Password',
        enter_email: 'Enter your email address below and we\'ll send you a link to reset your password',
        enter_email_to_verify: 'Enter your registered email to Verify',
        recover: 'Recover',
        reset_your_password: 'Reset Your Password',
        recovery_password: 'Recovery Password',
        change_password: 'Change Password',
        change_password_placeholder: 'Enter New Password',
        confirm_password_label: 'Confirm Password',
        confirm_password: 'Re-enter your password',
        dashboard: 'Dashboard',
        deals: 'Deals',
        sign_out: 'Sign Out',
        pending_exnt_stake: 'EXNT Unstake Requests pending',
        pending_exnt_claim: 'EXNG Claim Requests pending',
        staked_exnt: 'EXNT Staked',
        un_staked_exnt: 'EXNT Unstaked',
        circulating_exnt: 'EXNT Circulating',
        search_by_account: 'Search by Transaction ID, Amount, Status',
        view_details: 'View Details',
        requests: 'Requests',
        users: 'Users',
        total_active: 'Total, Active in the last',
        total_new_signups: 'Total new signups in the last',
        settings: 'Settings',
        account: 'Account',
        security: 'Security',
        two_factor_auth: 'Two Factor Authentication',
        enter_two_factor_auth: 'Enter Two Factor Authentication Code',
        enter_authentication_code: 'Enter Authentication Code',
        verify: 'Verify',
        two_factor_authentication: 'Two Factor Authentication/BackUp Code',
        verification: 'Two Factor Authentication',
        verification_text: 'Please enter your 6 digit code given by your Two Factor Authentication App that you used for your account.',
        tokens: 'Tokens',
        tipping: 'Tipping',
        new_deal: 'New Deal',
        new_deal_header: 'Create New Deal',
        update_deal_header: 'Update Deal',
        new_deal_form_name: 'Short Name',
        new_deal_form_full_name: 'Full Deal Name',
        new_deal_form_description: 'Description',
        new_deal_form_fee: 'Fee',
        new_deal_form_owner: 'Owner',
        new_deal_form_currency: 'Currency',
        new_deal_form_plan: 'Plan',
        new_deal_form_gather: 'Gatherdao URL',
        new_deal_form_etherscan: 'Etherscan Link',
        new_deal_form_notes: 'Notes',
        new_deal_form_status: 'Status',
        new_deal_form_round: 'Round',
        new_deal_form_allocation: 'Total Allocation',
        new_deal_form_link1: 'Link 1',
        new_deal_form_link2: 'Link 2',
        new_deal_form_link3: 'Link 3',
        new_deal_form_timezone: 'Deal Posting Timezone',
        new_deal_form_time: 'Deal Posting Time',
        new_deal_form_tier_limit: 'Deal Posting Tier Limit',
        new_deal_form_link_placeholder: 'Add link that is relevant to the deal',
        update_deal: 'Update Deal',
        close_deal: 'Close Deal',
        add_deal_success: 'Deal added successfully!',
        update_deal_success: 'Deal has been updated successfully',
        name_required: 'Name is required!',
        full_name_required: 'Full Name is required!',
        description_required: 'Description is required!',
        fee_required: 'Fee is required!',
        token_price_required: 'Token Price is required!',
        vesting_required: 'Vesting is required!',
        nextVault: 'NEXT Vaults',
        gatherdao_required: 'Gatherdao URL is required!',
        past_date: 'Do not choose a date from the past.',
        round_required: 'Round is required!',
        allocation_required: 'Total allocation is required!',
        timezone_required: 'Timezone is required!',
        submit: 'Submit',
        close_deal_warn_message: 'Are you sure to close this deal?',
        close_deal_warn_message_text: 'You cannot undo this action!',
        reopen_deal_warn_message: 'Are you sure to reopen this deal?',
        archive_deal_warn_message: 'Are you sure to archive this deal?',
        unarchive_deal_warn_message: 'Are you sure to restore this deal?',
        cancel: 'Cancel',
        referrals: 'Referrals',
        change_photo: 'Change Photo',
        edit_profile: 'Edit Profile',
        enter_name: 'Enter Name',
        save: 'Save',
        upload_photo: 'Upload Photo',
        bio: 'Bio',
        short_desc: 'Enter short description about yourself',
        profile: 'Profile',
        archive_deal: 'Archive Deal',
        unarchive_deal: 'Unarchive Deal',
        reopen_deal: 'Reopen Deal',
        add_distribution_details: 'Add Distribution Details',
        update_distribution_details: 'Update Distribution Details',
        add_details: 'Add Details',
        update_details: 'Update Details',
        distribution_details: 'Enter Distribution Details',
        token_generation_event: 'Token Generation Event',
        monthly_percentage: 'Monthly Percentage',
        cliff: 'Month Cliff',
        token_generation_event_required: 'Token Generation Event is required!',
        monthly_percentage_required: 'Monthly Percentage is required!',
        cliff_required: 'Cliff is required!',
        add_distribution_details_success: 'Successfully added distribution details!',
        due_date: 'Due Date',
        sent_date: 'Token Sent Date',
        update: 'Update',
        update_distribution_item_success: 'Successfully updated distribution item',
        fields_update_warn: 'Please fill in the fields you want to update',
        update_distribution_success: 'Successfully updated distribution details',
        vesting_details: 'Vesting Details',
        vesting_details_required: 'Vesting Details required!',
        inbox: 'Inbox',
        reply: 'reply',
        back: 'Back',
        sent_to: 'sent to',
        sent_by: 'sent by',
        search: 'Search',
        create_message: 'Create Message',
        no_message_available: 'No Messages Available',
        replies: 'replies',
        recipients: 'recipients',
        recipient: 'Recipient',
        subject: 'Subject',
        message_validate_sender: 'Missing Sender',
        message_validate_recipient: 'Missing Recipient',
        message_validate_receiver_type: 'Missing Receiver Type',
        message_validate_sender_type: 'Missing Sender Type',
        message_validate_subject: 'Missing Subject',
        message_validate_content: 'Missing Message Content',
    },
};

export const ERROR_MESSAGE = [
    'Failed fetch!',
    'Failed to fetch!',
    'Failed to update!',
    'Failed to add!',
    'Failed to delete!',
    'Failed to authenticate!',
    'Error: Request failed with status code 422',
    'Password does not match.',
];
export const SUCCESS_MESSAGE = [
    'Deal added successfully!',
    'Task Successfully updated.',
    'Task Successfully added.',
    'Distribution details added successfully.',
    variables.en.update_deal_success,
    variables.en.update_distribution_success,
    variables.en.add_distribution_details_success,
    variables.en.add_deal_success,
];

export default variables;
