import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import SignIn from './containers/SignIn';
import Dashboard from './containers/Dashboard';
import NavigationBar from './containers/NavigationBar';
import UnstakeRequests from './containers/UnstakeRequests';
import ExngClaims from './containers/ExngClaims';
import Users from './containers/Users';
import ForgotPassword from './containers/ForgotPassword';
import RecoveryPassword from './containers/RecoveryPassword';
import Settings from './containers/Settings';
import VerifyAuthentication from './containers/Authentication';
import Tipping from './containers/Tipping';
import Deals from './containers/Deals';
import DealDetails from './containers/DealDetails';
import ContibutorDetails from './containers/ContributorDetails';
import Referrals from './containers/Referrals';
import PublicProfile from './containers/ProfilePage/Public';
import ProfilePage from './containers/ProfilePage';
import Edit from './containers/ProfilePage/Edit';
import Tasks from './containers/Tasks';
import Forum from './containers/Forum';
import ForumDetail from './containers/ForumDetail';
import Inbox from './containers/Inbox/';
import MessageCreate from './containers/Inbox/Edit';
import MessageView from './containers/Inbox/View';
import NextVault from './containers/NextVault';
import NextVaultDetails from './containers/NextVaultDetails';

const routes = [{
    path: '/login',
    component: SignIn,
}, {
    path: '/forgot-password',
    component: ForgotPassword,
}, {
    path: '/forgot-password/recovery/:id/:code',
    component: RecoveryPassword,
}, {
    path: '/verify-auth',
    component: VerifyAuthentication,
}];

const protectedRoutes = [{
    path: '/dashboard',
    component: Dashboard,
}, {
    path: '/deals',
    component: Deals,
}, {
    path: '/next-vault',
    component: NextVault,
}, {
    path: '/next-vault/:id',
    component: NextVaultDetails,
}, {
    path: '/deals/:id',
    component: DealDetails,
}, {
    path: '/deals/:id/contributors/:contributorId',
    component: ContibutorDetails,
}, {
    path: '/users',
    component: Users,
}, {
    path: '/requests/unstake',
    component: UnstakeRequests,
}, {
    path: '/requests/exng-claims',
    component: ExngClaims,
}, {
    path: '/settings',
    component: Settings,
}, {
    path: '/tipping',
    component: Tipping,
}, {
    path: '/referrals',
    component: Referrals,
}, {
    path: '/user/:id',
    component: PublicProfile,
}, {
    path: '/profile',
    component: ProfilePage,
}, {
    path: '/profile/edit',
    component: Edit,
}, {
    path: '/tasks',
    component: Tasks,
}, {
    path: '/forums',
    component: Forum,
}, {
    path: '/forum/:id',
    component: ForumDetail,
}, {
    path: '/inbox',
    component: Inbox,
}, {
    path: '/message/create',
    component: MessageCreate,
}, {
    path: '/message/view/:id',
    component: MessageView,
}, {
    path: '/message/create/:id',
    component: MessageCreate,
}, {
    path: '/message/:id/reply/:messageID',
    component: MessageCreate,
}, {
    path: '/inbox/:id',
    component: Inbox,
}];

const Router = (props) => {
    const token = localStorage.getItem('accessToken');

    const showNavBar = props.location && props.location.pathname &&
        protectedRoutes.filter((value) => {
            return props.location.pathname.split('/')[1] === value.path.split('/')[1];
        });

    return (
        <div className="main_content">
            {showNavBar.length > 0 && token
                ? <NavigationBar/>
                : null}
            <div className="content_div scroll_bar">
                <Switch>
                    {token && protectedRoutes.map((route) =>
                        <Route
                            key={route.path}
                            exact
                            component={route.component}
                            path={route.path}/>,
                    )}
                    {routes.map((route) =>
                        <Route
                            key={route.path}
                            exact
                            component={route.component}
                            path={route.path}/>,
                    )}
                    <Route
                        exact
                        component={SignIn}
                        path="*"/>
                </Switch>
            </div>
        </div>
    );
};

Router.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
};

export default withRouter(Router);
