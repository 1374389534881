import Axios from 'axios';
import {
    FETCH_NEXT_VAULT_IN_PROGRESS,
    FETCH_NEXT_VAULT_SUCCESS,
    FETCH_NEXT_VAULT_ERROR,
    ADD_NEXT_VAULT_IN_PROGRESS,
    ADD_NEXT_VAULT_SUCCESS,
    ADD_NEXT_VAULT_ERROR,
    ADD_NEXT_VAULT_LOG_IN_PROGRESS,
    ADD_NEXT_VAULT_LOG_SUCCESS,
    ADD_NEXT_VAULT_LOG_ERROR,
    UPDATE_NEXT_VAULT_LOG_IN_PROGRESS,
    UPDATE_NEXT_VAULT_LOG_SUCCESS,
    UPDATE_NEXT_VAULT_LOG_ERROR,
    UPDATE_NEXT_VAULT_IN_PROGRESS,
    UPDATE_NEXT_VAULT_SUCCESS,
    UPDATE_NEXT_VAULT_ERROR,
    FETCH_NEXT_VAULT_DETAIL_IN_PROGRESS,
    FETCH_NEXT_VAULT_DETAIL_SUCCESS,
    FETCH_NEXT_VAULT_DETAIL_ERROR,
    FETCH_NEXT_VAULT_LOG_IN_PROGRESS,
    FETCH_NEXT_VAULT_LOG_SUCCESS,
    FETCH_NEXT_VAULT_LOG_ERROR,
} from '../constants/nextVault';
import { getNextVaultDetail, getNextVaultURL, getNextVaultLog, updateNextVaultLogURL, NEXT_VAULT_URL, NEXT_VAULT_LOG_URL } from '../constants/url';
const fetchNextVaultInProgress = () => {
    return {
        type: FETCH_NEXT_VAULT_IN_PROGRESS,
    };
};

const fetchNextVaultSuccess = (message) => {
    return {
        type: FETCH_NEXT_VAULT_SUCCESS,
        message,
    };
};

const fetchNextVaultError = (message) => {
    return {
        type: FETCH_NEXT_VAULT_ERROR,
        message,
    };
};

export const fetchNextVault = (skip, limit, sortBy, order, search = '') => (dispatch) => {
    dispatch(fetchNextVaultInProgress());

    const URL = getNextVaultURL(skip, limit, sortBy, order, search);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            dispatch(fetchNextVaultSuccess(res.data.result));
        })
        .catch((error) => {
            dispatch(fetchNextVaultError(error.response.data.message));
        });
};

const addNextVaultInProgress = () => {
    return {
        type: ADD_NEXT_VAULT_IN_PROGRESS,
    };
};

const addNextVaultSuccess = (message) => {
    return {
        type: ADD_NEXT_VAULT_SUCCESS,
        message,
    };
};

const addNextVaultError = (message) => {
    return {
        type: ADD_NEXT_VAULT_ERROR,
        message,
    };
};

export const addNextVault = (data, cb) => (dispatch) => {
    dispatch(addNextVaultInProgress());

    Axios.post(NEXT_VAULT_URL, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            dispatch(addNextVaultSuccess(res.data.result));
            cb(null, res.data.result);
        })
        .catch((error) => {
            dispatch(addNextVaultError(
                error.response &&
          error.response.data &&
          error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const updateNextVaultInProgress = () => {
    return {
        type: UPDATE_NEXT_VAULT_IN_PROGRESS,
    };
};

const updateNextVaultSuccess = (message) => {
    return {
        type: UPDATE_NEXT_VAULT_SUCCESS,
        message,
    };
};

const updateNextVaultError = (message) => {
    return {
        type: UPDATE_NEXT_VAULT_ERROR,
        message,
    };
};

export const updateNextVault = (data, cb) => (dispatch) => {
    dispatch(updateNextVaultInProgress());

    Axios.put(getNextVaultDetail(data.id), data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            dispatch(updateNextVaultSuccess(res.data.result));
            if (cb) {
                cb(null, res.data.result);
            }
        })
        .catch((error) => {
            dispatch(updateNextVaultError(
                error.response &&
          error.response.data &&
          error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchNextVaultDetailInProgress = () => {
    return {
        type: FETCH_NEXT_VAULT_DETAIL_IN_PROGRESS,
    };
};

const fetchNextVaultDetailSuccess = (message) => {
    return {
        type: FETCH_NEXT_VAULT_DETAIL_SUCCESS,
        message,
    };
};

const fetchNextVaultDetailError = (message) => {
    return {
        type: FETCH_NEXT_VAULT_DETAIL_ERROR,
        message,
    };
};

export const fetchNextVaultDetail = (id) => (dispatch) => {
    dispatch(fetchNextVaultDetailInProgress());

    const URL = getNextVaultDetail(id);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            dispatch(fetchNextVaultDetailSuccess(res.data.result));
        })
        .catch((error) => {
            dispatch(fetchNextVaultDetailError(error.response.data.error.details[0].message));
        });
};

const addNextVaultLogInProgress = () => {
    return {
        type: ADD_NEXT_VAULT_LOG_IN_PROGRESS,
    };
};

const addNextVaultLogSuccess = (message) => {
    return {
        type: ADD_NEXT_VAULT_LOG_SUCCESS,
        message,
    };
};

const addNextVaultLogError = (message) => {
    return {
        type: ADD_NEXT_VAULT_LOG_ERROR,
        message,
    };
};

export const addNextVaultLog = (data, cb) => (dispatch) => {
    dispatch(addNextVaultLogInProgress());

    Axios.post(NEXT_VAULT_LOG_URL, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            dispatch(addNextVaultLogSuccess(res.data.result));
            cb(null, res.data.result);
        })
        .catch((error) => {
            dispatch(addNextVaultLogError(
                error.response &&
          error.response.data &&
          error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const updateNextVaultLogInProgress = () => {
    return {
        type: UPDATE_NEXT_VAULT_LOG_IN_PROGRESS,
    };
};

const updateNextVaultLogSuccess = (message) => {
    return {
        type: UPDATE_NEXT_VAULT_LOG_SUCCESS,
        message,
    };
};

const updateNextVaultLogError = (message) => {
    return {
        type: UPDATE_NEXT_VAULT_LOG_ERROR,
        message,
    };
};

export const updateNextVaultLog = (data, cb) => (dispatch) => {
    dispatch(updateNextVaultLogInProgress());

    const URL = updateNextVaultLogURL(data.id);
    Axios.put(URL, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            dispatch(updateNextVaultLogSuccess(res.data.result));
            if (cb) {
                cb(null, res.data.result);
            }
        })
        .catch((error) => {
            dispatch(updateNextVaultLogError(
                error.response &&
          error.response.data &&
          error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

export const fetchNextVaultLogInprogress = () => {
    return {
        type: FETCH_NEXT_VAULT_LOG_IN_PROGRESS,
    };
};

export const fetchNextVaultLogSuccess = (message) => {
    return {
        type: FETCH_NEXT_VAULT_LOG_SUCCESS,
        message,
    };
};

export const fetchNextVaultLogError = (message) => {
    return {
        type: FETCH_NEXT_VAULT_LOG_ERROR,
        message,
    };
};

export const fetchNextVaultLog = (id, skip, limit, sortBy, order, search = '') => (dispatch) => {
    dispatch(fetchNextVaultLogInprogress());

    const URL = getNextVaultLog(id, skip, limit, sortBy, order, search);

    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            dispatch(fetchNextVaultLogSuccess(res.data.result));
        })
        .catch((error) => {
            dispatch(fetchNextVaultLogError(
                error.response &&
          error.response.data &&
          error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};
