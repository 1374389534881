import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import { Button } from '@material-ui/core';
import DataTable from '../../components/DataTable';
import NextVaultLogModal from '../NextVault/NextVaultLog/NextVaultLogModal';
import { fetchNextVaultLog } from '../../actions/nextVault';
import { NEXT_VAULT_LOG_COLUMNS, NEXT_VAULT_TABLE_OPTIONS } from '../../utils/variables/nextVault';
import { DEFAULT_SKIP } from '../../constants/url';

import './index.css';

const NextVaultLogTable = (props) => {
    const {
        fetch,
        list,
        count,
        id,
        isTriggerFetch,
        setTriggerFetch,
    } = props;
    const [sortBy, setSortBy] = useState('date');
    const [order, setOrder] = useState('desc');
    const [search, setSearch] = useState('');
    const [data, setData] = useState([]);
    const [details, setDetails] = useState({});
    const [openModal, setOpenModal] = useState(false);

    const columns = [...NEXT_VAULT_LOG_COLUMNS, {
        name: '_id',
        label: 'Actions',
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value) => {
                return (
                    <div className="flex gap-4">
                        <Button className="edit_details_button" onClick={() => onModalOpen(value)}>
                            UPDATE
                        </Button>
                    </div>
                );
            },
        },
    }];

    // const options = { ...NEXT_VAULT_TABLE_OPTIONS, count };
    const options = {
        ...NEXT_VAULT_TABLE_OPTIONS,
        count,
        onChangePage: (currentPage) => {
            fetch(id, currentPage * 50, 50, sortBy, order, search);
        },
        onSearchChange: (searchText) => {
            setSearch(searchText);
            fetch(id, 0, 50, sortBy, order, searchText);
        },
        onColumnSortChange: (changedColumn, direction) => {
            setSortBy(changedColumn);
            setOrder(direction);
            fetch(id, 0, 50, changedColumn, direction, search);
        },
    };

    useEffect(() => {
        fetch(id, DEFAULT_SKIP, 50, sortBy, order);
    }, []);

    useEffect(() => {
        if (isTriggerFetch === true) {
            fetch(id, DEFAULT_SKIP, 50, sortBy, order);
            setTriggerFetch(false);
        }
    }, [isTriggerFetch]);

    useEffect(() => {
        if (list) {
            setData(list.map((item) => ({
                _id: item._id,
                author: item.author.email_address || 0,
                amount: item.amount,
                transaction_link: item.transaction_link || '',
                date: moment(item.date).format('DD MMM YYYY'),
                action: item._id,
            })));
        }
    }, [list]);

    const onModalClose = () => {
        fetch(id, DEFAULT_SKIP, 50, sortBy, order);
        setOpenModal(false);
    };

    const onModalOpen = (id) => {
        const currentItem = list.find((i) => i._id === id);
        setDetails({
            ...currentItem,
            author: currentItem.author.email_address,
        });
        setOpenModal(true);
    };

    return (
        <div className="table w-full bg-custom-dark px-2 py-8 md:p-8 rounded-md">
            <DataTable
                columns={columns}
                data={data}
                name="NEXT Vault"
                options={options}
            />
            <NextVaultLogModal details={details} open={openModal} onClose={onModalClose} />
        </div>
    );
};

NextVaultLogTable.propTypes = {
    count: PropTypes.number.isRequired,
    fetch: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    isTriggerFetch: PropTypes.bool,
    setTriggerFetch: PropTypes.func,
};

const stateToProps = (state) => {
    return {
        list: state.nextVault.nextVaultLogs.list,
        count: state.nextVault.nextVaultLogs.count,
    };
};

const actionToProps = {
    fetch: fetchNextVaultLog,
};

export default connect(stateToProps, actionToProps)(NextVaultLogTable);
