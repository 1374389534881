import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import DetailSection from './DetailSection';
import { connect } from 'react-redux';
import { fetchNextVaultDetail } from '../../actions/nextVault';
import NextVaultModal from '../NextVault/NextVaultModal';
import NextVaultLogModal from '../NextVault/NextVaultLog/NextVaultLogModal';
import { fetchProfileDetails } from '../../actions/navigationBar';
import NextVaultLogTable from './NextVaultLogTable';
import Icon from '../../components/Icon';
import Button from '../../components/Button';

const NextVaultDetails = (props) => {
    const history = useHistory();
    const {
        details,
        errorMessage,
        fetchNextVaultDetail,
        match: {
            params: {
                id,
            },
        },
        profileDetails,
    } = props;

    const [openModal, setOpenModal] = useState(false);
    const [openVaultLogModal, setOpenVaultLogModal] = useState(false);
    const [vaultLogDetails, setVaultLogDetails] = useState({});
    const [fetchVaultLogs, setFetchVaultLogs] = useState(false);

    useEffect(() => {
        fetchProfileDetails();
    }, []);

    useEffect(() => {
        if (id) {
            fetchNextVaultDetail(id);
        }
    }, [id]);

    useEffect(() => {
        if (details && profileDetails) {
            setVaultLogDetails({
                author: profileDetails.email_address,
                next_vault_id: details._id,
            });
        }
    }, [details, profileDetails]);

    const redirectNextVault = () => {
        history.push('/next-vault');
    };

    const onModalClose = () => {
        fetchNextVaultDetail(id);
        setOpenModal(false);
    };

    const onModalOpen = () => {
        setOpenModal(true);
    };

    const onVaultLogModalClose = () => {
        setOpenVaultLogModal(false);
        setFetchVaultLogs(true);
    };

    const onVaultLogModalOpen = () => {
        setOpenVaultLogModal(true);
    };

    return (
        <div className="next-vault content_padding">
            {!errorMessage && (
                <>
                    <div className="mb-4">
                        <Button className="flex items-center" onClick={redirectNextVault}>
                            <Icon
                                className="dark-back-arrow mr-2"
                                icon="dark-back-arrow"/>
                            <span className="font-oswald-text font-bold text-white">Back</span>
                        </Button>
                    </div>
                    <DetailSection details={details} openLogModal={onVaultLogModalOpen} openModal={onModalOpen} />
                    <NextVaultModal details={details} open={openModal} onClose={onModalClose} />
                    <NextVaultLogTable id={id} isTriggerFetch={fetchVaultLogs} setTriggerFetch={setFetchVaultLogs} />
                    <NextVaultLogModal details={vaultLogDetails} open={openVaultLogModal} onClose={onVaultLogModalClose} />
                </>
            )}
        </div>
    );
};

NextVaultDetails.propTypes = {
    details: PropTypes.object.isRequired,
    errorMessage: PropTypes.string.isRequired,
    fetchNextVaultDetail: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired,
    }).isRequired,
    profileDetails: PropTypes.object.isRequired,
    isLastField: PropTypes.bool,
};

const stateToProps = (state) => {
    return {
        details: state.nextVault.nextVaultDetails.result,
        errorMessage: state.nextVault.nextVaultDetails.message || '',
        profileDetails: state.navigationBar.profileDetails.value,
    };
};

const actionToProps = {
    fetchNextVaultDetail,
    fetchProfileDetails,
};

export default connect(stateToProps, actionToProps)(NextVaultDetails);
