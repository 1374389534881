export const ACTIVE_FILTER_SET = 'ACTIVE_FILTER_SET';
export const SIGNUPS_FILTER_SET = 'SIGNUPS_FILTER_SET';

export const USERS_LIST_FETCH_IN_PROGRESS = 'USERS_LIST_FETCH_IN_PROGRESS';
export const USERS_LIST_FETCH_SUCCESS = 'USERS_LIST_FETCH_SUCCESS';
export const USERS_LIST_FETCH_ERROR = 'USERS_LIST_FETCH_ERROR';

export const SPECIFIC_USER_INFO_ERROR = 'SPECIFIC_USER_INFO_ERROR';
export const SPECIFIC_USER_INFO_IN_PROGRESS = 'SPECIFIC_USER_INFO_IN_PROGRESS';
export const SPECIFIC_USER_INFO_SUCCESS = 'SPECIFIC_USER_INFO_SUCCESS';

export const SPECIFIC_PROFILE_IMAGE_FETCH_IN_PROGRESS = 'SPECIFIC_PROFILE_IMAGE_FETCH_IN_PROGRESS';
export const SPECIFIC_PROFILE_IMAGE_FETCH_SUCCESS = 'SPECIFIC_PROFILE_IMAGE_FETCH_SUCCESS';
export const SPECIFIC_PROFILE_IMAGE_FETCH_ERROR = 'SPECIFIC_PROFILE_IMAGE_FETCH_ERROR';

export const SPECIFIC_BANNER_IMAGE_FETCH_IN_PROGRESS = 'SPECIFIC_BANNER_IMAGE_FETCH_IN_PROGRESS';
export const SPECIFIC_BANNER_IMAGE_FETCH_SUCCESS = 'SPECIFIC_BANNER_IMAGE_FETCH_SUCCESS';
export const SPECIFIC_BANNER_IMAGE_FETCH_ERROR = 'SPECIFIC_BANNER_IMAGE_FETCH_ERROR';

export const FETCH_CORE_USERS_IN_PROGRESS = 'FETCH_CORE_USERS_IN_PROGRESS';
export const FETCH_CORE_USERS_SUCCESS = 'FETCH_CORE_USERS_SUCCESS';
export const FETCH_CORE_USERS_ERROR = 'FETCH_CORE_USERS_ERROR';

export const FETCH_NEXT_USERS_IN_PROGRESS = 'FETCH_NEXT_USERS_IN_PROGRESS';
export const FETCH_NEXT_USERS_SUCCESS = 'FETCH_NEXT_USERS_SUCCESS';
export const FETCH_NEXT_USERS_ERROR = 'FETCH_NEXT_USERS_ERROR';

export const FETCH_USERS_TIER_IN_PROGRESS = 'FETCH_USERS_TIER_IN_PROGRESS';
export const FETCH_USERS_TIER_SUCCESS = 'FETCH_USERS_TIER_SUCCESS';
export const FETCH_USERS_TIER_ERROR = 'FETCH_USERS_TIER_ERROR';

export const FETCH_USER_WITH_STAKED_FETCH_IN_PROGRESS = 'FETCH_USER_WITH_STAKED_FETCH_IN_PROGRESS';
export const FETCH_USER_WITH_STAKED_FETCH_SUCCESS = 'FETCH_USER_WITH_STAKED_FETCH_SUCCESS';
export const FETCH_USER_WITH_STAKED_FETCH_ERROR = 'FETCH_USER_WITH_STAKED_FETCH_ERROR';

export const FETCH_USERS_UNSTAKED_IN_PROGRESS = 'FETCH_USERS_UNSTAKED_IN_PROGRESS';
export const FETCH_USERS_UNSTAKED_SUCCESS = 'FETCH_USERS_UNSTAKED_SUCCESS';
export const FETCH_USERS_UNSTAKED_ERROR = 'FETCH_USERS_UNSTAKED_ERROR';
