import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import DataTable from '../../components/DataTable';
// import variables from '../../utils/variables';
import { fetchUsersTier } from '../../actions/users';
import {
    DEFAULT_SKIP,
} from '../../constants/url';
import moment from 'moment/moment';

const UserStakeTable = (props) => {
    const {
        fetch,
        pagination,
        // lang,
        count,
        list,
    } = props;
    const [search, setSearch] = useState('');
    const [sortBy, setSortBy] = useState('last_staked');
    const [order, setOrder] = useState('desc');

    useEffect(() => {
        fetch(DEFAULT_SKIP, 50, sortBy, order);
    }, []);

    const options = {
        responsive: 'vertical',
        serverSide: true,
        print: false,
        pagination,
        count,
        download: false,
        selectableRows: 'none',
        rowsPerPage: 50,
        onChangePage: (currentPage) => {
            fetch(currentPage * 50, 50, sortBy, order, search);
        },
        onSearchChange: (searchText) => {
            setSearch(searchText);
            fetch(0, 50, sortBy, order, searchText);
        },
        onColumnSortChange: (changedColumn, direction) => {
            setSortBy(changedColumn);
            setOrder(direction);
            fetch(0, 50, changedColumn, direction, search);
        },
    };

    const columns = [
        {
            name: 'telegram_username',
            label: 'Telegram Username',
            options: {
                filter: false,
                sort: true,
                sortOrder: 'asc',
            },
        },
        {
            name: 'wallet',
            label: 'Wallet Connected',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: 'stake_value',
            label: 'EXNT Staked',
            options: {
                filter: false,
                sort: true,
                sortOrder: 'asc',
            },
        },
        {
            name: 'email_address',
            label: 'Email Address',
            options: {
                filter: false,
                sort: true,
                sortOrder: 'asc',
            },
        },
        {
            name: 'top_tier',
            label: 'Tiers',
            options: {
                filter: false,
                sort: true,
                sortOrder: 'asc',
            },
        },
        {
            name: 'last_staked',
            label: 'Last Staked',
            options: {
                filter: false,
                sort: true,
                sortOrder: 'desc',
            },
        },
    ];

    const tableData = list ? list.map((item) => [
        item.telegram_username || '',
        item.wallet,
        item.staked || 0,
        item.email_address,
        item.top_tier,
        moment(item.last_staked).format('YYYY-MM-DD @ HH:mm'),
    ]) : [];

    return (
        <div className="table w-full bg-custom-dark text-white font-oswald-text p-4 rounded-md">
            <DataTable
                columns={columns}
                data={tableData}
                name="TIER LIST"
                options={options} />
        </div>
    );
};

UserStakeTable.propTypes = {
    count: PropTypes.number.isRequired,
    fetch: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    pagination: PropTypes.bool,
};

const stateToProps = (state) => {
    return {
        auth: state.accessToken.value,
        lang: state.language,
        list: state.users.userTiers.value,
        count: state.users.userTiers.count,
    };
};

const actionToProps = {
    fetch: fetchUsersTier,
};

export default connect(stateToProps, actionToProps)(UserStakeTable);
