import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
// import variables from '../../utils/variables';
import { fetchUserWithStaked } from '../../actions/users';

const DownloadStakedUsers = (props) => {
    const { fetch, list } = props;
    const headers = [
        { label: 'Telegram Username', key: 'telegram_username' },
        { label: 'Email', key: 'email_address' },
        { label: 'Wallet', key: 'wallet' },
        { label: 'Staked', key: 'staked' },
    ];

    useEffect(() => {
        fetch();
    }, []);

    return (
        <div className="w-full bg-custom-dark text-white font-oswald-text p-4 rounded-md">
            <CSVLink data={list && list?.url && list?.url?.users ? list.url.users : []} filename={'users-staked.csv'} headers={headers}>
                <button className="btn--primary btn--small border bg-custom-dark-mode-red border-custom-dark-mode-red rounded-md px-6 py-2">
                    <span className="text-white font-oswald-text">Download CSV</span>
                </button>
            </CSVLink>
        </div>
    );
};

DownloadStakedUsers.propTypes = {
    fetch: PropTypes.func.isRequired,
    list: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        list: state.users.stakedUsers.value,
    };
};

const actionToProps = {
    fetch: fetchUserWithStaked,
};

export default connect(stateToProps, actionToProps)(DownloadStakedUsers);
