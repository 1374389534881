import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';

const DetailField = (props) => {
    const { label, value, isLastField = false } = props;
    return (
        <div className={`flex flex-col ${!isLastField ? 'mb-6' : ''}`}>
            <label className="font-oswald-text text-white opacity-50 capitalize mb-1 text-sm">{label}</label>
            <p className="font-oswald-text text-white text-lg">{value}</p>
        </div>
    );
};

DetailField.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    isLastField: PropTypes.bool,
};

const stateToProps = (state) => {
    return {
    };
};

const actionToProps = {};

export default connect(stateToProps, actionToProps)(DetailField);
