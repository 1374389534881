export const FETCH_NEXT_VAULT_IN_PROGRESS = 'FETCH_NEXT_VAULT_IN_PROGRESS';
export const FETCH_NEXT_VAULT_SUCCESS = 'FETCH_NEXT_VAULT_SUCCESS';
export const FETCH_NEXT_VAULT_ERROR = 'FETCH_NEXT_VAULT_ERROR';
export const ADD_NEXT_VAULT_IN_PROGRESS = 'ADD_NEXT_VAULT_IN_PROGRESS';
export const ADD_NEXT_VAULT_SUCCESS = 'ADD_NEXT_VAULT_SUCCESS';
export const ADD_NEXT_VAULT_ERROR = 'ADD_NEXT_VAULT_ERROR';
export const UPDATE_NEXT_VAULT_IN_PROGRESS = 'UPDATE_NEXT_VAULT_IN_PROGRESS';
export const UPDATE_NEXT_VAULT_SUCCESS = 'UPDATE_NEXT_VAULT_SUCCESS';
export const UPDATE_NEXT_VAULT_ERROR = 'UPDATE_NEXT_VAULT_ERROR';
export const FETCH_NEXT_VAULT_DETAIL_IN_PROGRESS = 'FETCH_NEXT_VAULT_DETAIL_IN_PROGRESS';
export const FETCH_NEXT_VAULT_DETAIL_SUCCESS = 'FETCH_NEXT_VAULT_DETAIL_SUCCESS';
export const FETCH_NEXT_VAULT_DETAIL_ERROR = 'FETCH_NEXT_VAULT_DETAIL_ERROR';
export const ADD_NEXT_VAULT_LOG_IN_PROGRESS = 'ADD_NEXT_VAULT_LOG_IN_PROGRESS';
export const ADD_NEXT_VAULT_LOG_SUCCESS = 'ADD_NEXT_VAULT_LOG_SUCCESS';
export const ADD_NEXT_VAULT_LOG_ERROR = 'ADD_NEXT_VAULT_LOG_ERROR';
export const FETCH_NEXT_VAULT_LOG_IN_PROGRESS = 'FETCH_NEXT_VAULT_LOG_IN_PROGRESS';
export const FETCH_NEXT_VAULT_LOG_SUCCESS = 'FETCH_NEXT_VAULT_LOG_SUCCESS';
export const FETCH_NEXT_VAULT_LOG_ERROR = 'FETCH_NEXT_VAULT_LOG_ERROR';
export const UPDATE_NEXT_VAULT_LOG_IN_PROGRESS = 'UPDATE_NEXT_VAULT_LOG_IN_PROGRESS';
export const UPDATE_NEXT_VAULT_LOG_SUCCESS = 'UPDATE_NEXT_VAULT_LOG_SUCCESS';
export const UPDATE_NEXT_VAULT_LOG_ERROR = 'UPDATE_NEXT_VAULT_LOG_ERROR';
