import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import CloseDialog from '../../../components/CloseDialog';
import TextField from '../../../components/TextField';
import DatePickerField from '../../../components/DatePickerField';
import Button from '../../../components/Button';
import Snackbar from '../../../components/Snackbar';
import { connect } from 'react-redux';
import { NEXT_VAULT_LOG_DEFAULT_VALUE } from '../../../utils/variables/nextVault';
import { addNextVaultLog, updateNextVaultLog } from '../../../actions/nextVault';

const NextVaultLogModal = (props) => {
    const {
        onClose,
        open,
        addNextVaultLog,
        updateNextVaultLog,
        details,
    } = props;
    const [nextVaultLog, setNextVaultLog] = useState(
        NEXT_VAULT_LOG_DEFAULT_VALUE,
    );
    const [snackbar, setSnackbar] = useState({
        message: '',
        open: false,
        severity: 'error',
    });

    useEffect(() => {
        if (details._id) {
            setNextVaultLog((v) => ({
                ...v,
                id: details._id,
                date: new Date(details.date),
                amount: details.amount,
                transaction_link: details.transaction_link,
            }));
        }
    }, [details]);

    const openSnackbarOnError = (message) => {
        setSnackbar((v) => ({ ...v, open: true, message, severity: 'error' }));
    };

    const openSnackbarOnSuccess = (message) => {
        setSnackbar((v) => ({ ...v, open: true, message, severity: 'success' }));
    };

    const resetForm = () => {
        setNextVaultLog(NEXT_VAULT_LOG_DEFAULT_VALUE);
    };

    const checkFormValidation = () => {
        const { date, amount } = nextVaultLog;
        if (!date) {
            openSnackbarOnError('Date is required');
            return false;
        }

        if (!amount) {
            openSnackbarOnError('Amount is required');
            return false;
        }

        return true;
    };

    const submitForm = () => {
        const isFormValid = checkFormValidation();

        if (isFormValid) {
            if (nextVaultLog.id) {
                updateNextVaultLog(nextVaultLog, (error, result) => {
                    if (result) {
                        openSnackbarOnSuccess('Successfully updated next vault log');
                        handleDialogClose();
                    } else if (error) {
                        openSnackbarOnError(`${error}`);
                    }
                });
            } else {
                const payload = {
                    ...nextVaultLog,
                    next_vault_id: details.next_vault_id,
                };
                console.log(payload);
                addNextVaultLog(payload, (error, result) => {
                    if (result) {
                        openSnackbarOnSuccess('Successfully added next vault log');
                        handleDialogClose();
                    } else if (error) {
                        openSnackbarOnError(`${error}`);
                    }
                });
            }
        }
    };

    const handleSnackbarClose = () => {
        setSnackbar((v) => ({ ...v, open: false }));
    };

    const handleDialogClose = () => {
        onClose();
        resetForm();
    };

    return (
        <>
            <Snackbar
                message={snackbar.message}
                open={snackbar.open}
                severity={snackbar.severity}
                onClose={handleSnackbarClose}
            />
            <Dialog
                aria-describedby="next-vault-dialog-description"
                aria-labelledby="next-vault-dialog-title"
                className="dialog next-vault-log-modal"
                maxWidth="lg"
                open={open}
                onClose={handleDialogClose}
            >
                <DialogContent className="content relative text-white">
                    <p className="font-oswald-text font-bold text-2xl">VAULT LOG</p>
                    <CloseDialog
                        className="absolute top-4 md:top-10 right-6 md:right-14"
                        onClose={handleDialogClose}
                    />
                    <form
                        noValidate
                        autoComplete="off"
                        className="form flex flex-col md:flex-row mt-6"
                    >
                        <div className="w-full">
                            <div className="flex gap-x-4">
                                <div className="w-1/2">
                                    <div className="flex flex-col mb-4 font-oswald-text">
                                        <p className="block text-base font-semibold">
                                            <span className="text-white ">Author</span>
                                        </p>
                                        <TextField
                                            disable={true}
                                            id="next_vault_author"
                                            name="author"
                                            value={details.author}
                                            onChange={() => false}
                                        />
                                    </div>
                                </div>
                                <div className="w-1/2">
                                    <div className="next-vault-log__date flex flex-col mb-4 font-oswald-text">
                                        <DatePickerField
                                            label="Date"
                                            placeholderText="Select date"
                                            required={true}
                                            value={nextVaultLog.date}
                                            onChange={(e) =>
                                                setNextVaultLog((v) => ({ ...v, date: e }))
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col mb-4 font-oswald-text">
                                <p className="block text-base font-semibold">
                                    <span className="text-white ">Amount</span>
                                    <span className="text-custom-required">&nbsp;*</span>
                                </p>
                                <TextField
                                    id="next_vault_amount"
                                    name="amount"
                                    placeholder="Enter amount"
                                    type="number"
                                    value={nextVaultLog.amount}
                                    onChange={(e) => setNextVaultLog((v) => ({ ...v, amount: e }))}
                                />
                            </div>
                            <div className="flex flex-col mb-4 font-oswald-text">
                                <p className="block text-base font-semibold">
                                    <span className="text-white ">Transaction Link</span>
                                </p>
                                <TextField
                                    id="next_vault_transaction_link"
                                    name="transaction_link"
                                    placeholder="Enter transaction link"
                                    value={nextVaultLog.transaction_link}
                                    onChange={(e) => setNextVaultLog((v) => ({ ...v, transaction_link: e }))}
                                />
                            </div>
                        </div>
                    </form>
                </DialogContent>
                <DialogActions className="actions">
                    <div className="modal-footer">
                        <Button
                            className="mx-2 w-full font-oswald-text"
                            color="primary"
                            size="lg"
                            onClick={submitForm}
                        >
                            <span>Submit</span>
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
};

NextVaultLogModal.propTypes = {
    addNextVaultLog: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    updateNextVaultLog: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    details: PropTypes.object,
};

const stateToProps = (state) => {
    return {};
};

const actionToProps = {
    addNextVaultLog,
    updateNextVaultLog,
};

export default connect(stateToProps, actionToProps)(NextVaultLogModal);
