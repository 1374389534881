import React from 'react';
import { useHistory } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import DetailField from './DetailField';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import { connect } from 'react-redux';
import moment from 'moment';

const DetailSection = (props) => {
    const history = useHistory();
    const { details, openLogModal, openModal } = props;

    const redirectNextVault = () => {
        history.push('/next-vault');
    };

    return (
        <div className="table w-full bg-custom-dark px-2 py-8 md:p-8 rounded-md">
            <div className="px-7 py-4 flex">
                <div className="w-1/2">
                    <button className="pb-8" onClick={redirectNextVault}>
                        <h1 className="font-oswald-text text-2xl font-semibold">NEXT Vault</h1>
                    </button>
                    <DetailField label="Name" value={ details.name || '' } />
                    <DetailField label="Description" value={ details.description || '' } />
                    <DetailField label="Nav" value={ `$${details.nav || 0}` } />
                    <DetailField label="Contribution Start Date" value={moment(details.contribution_start).format('MMMM DD YYYY') || ''} />
                    <DetailField label="Contribution End Date" value={moment(details.contribution_end).format('MMMM DD YYYY') || ''} />
                    <DetailField isLastField={true} label="Maturity" value={moment(details.maturity_date).format('MMMM DD YYYY') || ''} />
                </div>
                <div className="w-1/2 flex items-end justify-end">
                    <div className="w-full max-w-xs flex gap-2">
                        <Button className="mx-2 w-full font-oswald-text" color="primary" size="slim-lg" onClick={openModal}>
                            <span>Update</span>
                        </Button>
                        <Button className="mx-2 w-full font-oswald-text" color="primary" size="slim-lg" onClick={openLogModal}>
                            <Icon className="cross w-3.5 mr-2" icon="cross"/>
                            <span>Add Log</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

DetailSection.propTypes = {
    details: PropTypes.object.isRequired,
    openLogModal: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {};
};

const actionToProps = {};

export default connect(stateToProps, actionToProps)(DetailSection);
