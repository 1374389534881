import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import './index.css';

const MaturityDateField = (props) => {
    const {
        value,
        onChange,
    } = props;

    const onFieldChange = (e) => {
        onChange(e.target.value);
    };

    return (
        <>
            <TextField
                select
                className="maturity-date rounded-md bg-custom-dark-2 w-full font-oswald-text text-white font-semibold"
                margin="none"
                placeholder="Select Maturity Date"
                value={value}
                onChange={onFieldChange}>
                <MenuItem value="1">1 year</MenuItem>
                <MenuItem value="2">2 years</MenuItem>
                <MenuItem value="3">3 years</MenuItem>
                <MenuItem value="4">4 years</MenuItem>
            </TextField>
        </>
    );
};

MaturityDateField.propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default MaturityDateField;
