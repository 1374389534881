import {
    ACTIVE_FILTER_SET,
    SIGNUPS_FILTER_SET,
    USERS_LIST_FETCH_ERROR,
    USERS_LIST_FETCH_IN_PROGRESS,
    USERS_LIST_FETCH_SUCCESS,
    SPECIFIC_USER_INFO_ERROR,
    SPECIFIC_USER_INFO_IN_PROGRESS,
    SPECIFIC_USER_INFO_SUCCESS,
    SPECIFIC_PROFILE_IMAGE_FETCH_IN_PROGRESS,
    SPECIFIC_PROFILE_IMAGE_FETCH_SUCCESS,
    SPECIFIC_PROFILE_IMAGE_FETCH_ERROR,
    SPECIFIC_BANNER_IMAGE_FETCH_IN_PROGRESS,
    SPECIFIC_BANNER_IMAGE_FETCH_SUCCESS,
    SPECIFIC_BANNER_IMAGE_FETCH_ERROR,
    FETCH_CORE_USERS_IN_PROGRESS,
    FETCH_CORE_USERS_SUCCESS,
    FETCH_CORE_USERS_ERROR,
    FETCH_NEXT_USERS_IN_PROGRESS,
    FETCH_NEXT_USERS_SUCCESS,
    FETCH_NEXT_USERS_ERROR,
    FETCH_USERS_TIER_IN_PROGRESS,
    FETCH_USERS_TIER_SUCCESS,
    FETCH_USERS_TIER_ERROR,
    FETCH_USER_WITH_STAKED_FETCH_IN_PROGRESS,
    FETCH_USER_WITH_STAKED_FETCH_SUCCESS,
    FETCH_USER_WITH_STAKED_FETCH_ERROR,
    FETCH_USERS_UNSTAKED_IN_PROGRESS,
    FETCH_USERS_UNSTAKED_SUCCESS,
    FETCH_USERS_UNSTAKED_ERROR,
} from '../constants/users';
import {
    usersListURL,
    specificUserInfo,
    specificUserimage,
    specificUserBanner,
    getUsersByTierURL,
    getUsersTierURL,
    getUsersUnstakedRequestURL,
    FETCH_USER_WITH_STAKED_URL,
} from '../constants/url';
import Axios from 'axios';

export const setActiveFilter = (value) => {
    return {
        type: ACTIVE_FILTER_SET,
        value,
    };
};

export const setSignupsFilter = (value) => {
    return {
        type: SIGNUPS_FILTER_SET,
        value,
    };
};

const fetchUsersListInProgress = () => {
    return {
        type: USERS_LIST_FETCH_IN_PROGRESS,
    };
};

const fetchUsersListSuccess = (value, skip, limit, searchKey, sortBy, order) => {
    return {
        type: USERS_LIST_FETCH_SUCCESS,
        value,
        skip,
        limit,
        searchKey,
        sortBy,
        order,
    };
};

const fetchUsersListError = (message) => {
    return {
        type: USERS_LIST_FETCH_ERROR,
        message,
    };
};

export const fetchUsersList = (skip, limit, searchKey, sortBy, order) => (dispatch) => {
    dispatch(fetchUsersListInProgress());

    const URL = usersListURL(skip, limit, searchKey, sortBy, order);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            dispatch(fetchUsersListSuccess(res.data && res.data.result,
                skip, limit, searchKey, sortBy, order));
        })
        .catch((error) => {
            dispatch(fetchUsersListError(
                error.response &&
                    error.response.data &&
                    error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchCoreUsersInProgress = () => {
    return {
        type: FETCH_CORE_USERS_IN_PROGRESS,
    };
};

const fetchCoreUsersSuccess = (value) => {
    return {
        type: FETCH_CORE_USERS_SUCCESS,
        users: value.users,
        count: value.count,
    };
};

const fetchCoreUsersError = (message) => {
    return {
        type: FETCH_CORE_USERS_ERROR,
        message,
    };
};

export const fetchCoreUsers = (skip, limit, sortBy, order, search = '') => (dispatch) => {
    dispatch(fetchCoreUsersInProgress());

    const URL = getUsersByTierURL(skip, limit, 'CORE TIER', sortBy, order, search);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    }).then((res) => {
        dispatch(fetchCoreUsersSuccess(res.data && res.data.result));
    }).catch((error) => {
        dispatch(fetchCoreUsersError(
            error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : 'Failed to fetch!',
        ));
    });
};

const fetchNextUsersInProgress = () => {
    return {
        type: FETCH_NEXT_USERS_IN_PROGRESS,
    };
};

const fetchNextUsersSuccess = (value) => {
    return {
        type: FETCH_NEXT_USERS_SUCCESS,
        users: value.users,
        count: value.count,
    };
};

const fetchNextUsersError = (message) => {
    return {
        type: FETCH_NEXT_USERS_ERROR,
        message,
    };
};

export const fetchNextUsers = (skip, limit, sortBy, order, search = '') => (dispatch) => {
    dispatch(fetchNextUsersInProgress());

    const URL = getUsersByTierURL(skip, limit, 'NEXT TIER', sortBy, order, search);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    }).then((res) => {
        dispatch(fetchNextUsersSuccess(res.data && res.data.result));
    }).catch((error) => {
        dispatch(fetchNextUsersError(
            error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : 'Failed to fetch!',
        ));
    });
};

const fetchSpecificUserInProgress = () => {
    return {
        type: SPECIFIC_USER_INFO_IN_PROGRESS,
    };
};

const fetchSpecificUserSuccess = (value) => {
    return {
        type: SPECIFIC_USER_INFO_SUCCESS,
        value,
    };
};

const fetchSpecificUserError = (message) => {
    return {
        type: SPECIFIC_USER_INFO_ERROR,
        message,
    };
};

export const fetchSpecificUser = (id) => (dispatch) => {
    dispatch(fetchSpecificUserInProgress());

    const URL = specificUserInfo(id);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
        },
    })
        .then((res) => {
            dispatch(fetchSpecificUserSuccess(res.data));
        })
        .catch((error) => {
            dispatch(fetchSpecificUserError(
                error.response &&
                    error.response.data &&
                    error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchSpecificProfileImageInProgress = () => {
    return {
        type: SPECIFIC_PROFILE_IMAGE_FETCH_IN_PROGRESS,
    };
};

const fetchSpecificProfileImageSuccess = (value) => {
    return {
        type: SPECIFIC_PROFILE_IMAGE_FETCH_SUCCESS,
        value,
    };
};

const fetchSpecificProfileImageError = (message) => {
    return {
        type: SPECIFIC_PROFILE_IMAGE_FETCH_ERROR,
        message,
    };
};

export const fetchSpecificProfileImage = (id) => (dispatch) => {
    dispatch(fetchSpecificProfileImageInProgress());

    const URL = specificUserimage(id);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            const imageUrl = { url: res.data.result };
            dispatch(fetchSpecificProfileImageSuccess(imageUrl));
        })
        .catch((error) => {
            dispatch(fetchSpecificProfileImageError(
                error.response &&
                    error.response.data &&
                    error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchSpecificBannerImageInProgress = () => {
    return {
        type: SPECIFIC_BANNER_IMAGE_FETCH_IN_PROGRESS,
    };
};

const fetchSpecificBannerImageSuccess = (value) => {
    return {
        type: SPECIFIC_BANNER_IMAGE_FETCH_SUCCESS,
        value,
    };
};

const fetchSpecificBannerImageError = (message) => {
    return {
        type: SPECIFIC_BANNER_IMAGE_FETCH_ERROR,
        message,
    };
};

export const fetchSpecificBannerImage = (id) => (dispatch) => {
    dispatch(fetchSpecificBannerImageInProgress());

    const URL = specificUserBanner(id);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            const imageUrl = { url: res.data.result };
            dispatch(fetchSpecificBannerImageSuccess(imageUrl));
        })
        .catch((error) => {
            dispatch(fetchSpecificBannerImageError(
                error.response &&
                    error.response.data &&
                    error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchUserWithStakedInProgress = () => {
    return {
        type: FETCH_USER_WITH_STAKED_FETCH_IN_PROGRESS,
    };
};

const fetchUserWithStakedSuccess = (value) => {
    return {
        type: FETCH_USER_WITH_STAKED_FETCH_SUCCESS,
        value,
    };
};

const fetchUserWithStakedError = (message) => {
    return {
        type: FETCH_USER_WITH_STAKED_FETCH_ERROR,
        message,
    };
};

export const fetchUserWithStaked = () => (dispatch) => {
    dispatch(fetchUserWithStakedInProgress());

    const URL = FETCH_USER_WITH_STAKED_URL;
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            const imageUrl = { url: res.data.result };
            dispatch(fetchUserWithStakedSuccess(imageUrl));
        })
        .catch((error) => {
            dispatch(fetchUserWithStakedError(
                error.response &&
                    error.response.data &&
                    error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchUsersTierInProgress = () => {
    return {
        type: FETCH_USERS_TIER_IN_PROGRESS,
    };
};

const fetchUsersTierSuccess = (value) => {
    return {
        type: FETCH_USERS_TIER_SUCCESS,
        users: value.users,
        count: value.count,
    };
};

const fetchUsersTierError = (message) => {
    return {
        type: FETCH_USERS_TIER_ERROR,
        message,
    };
};

export const fetchUsersTier = (skip, limit, sortBy, order, search = '') => (dispatch) => {
    dispatch(fetchUsersTierInProgress());

    const URL = getUsersTierURL(skip, limit, sortBy, order, search);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    }).then((res) => {
        dispatch(fetchUsersTierSuccess(res.data && res.data.result));
    }).catch((error) => {
        dispatch(fetchUsersTierError(
            error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : 'Failed to fetch!',
        ));
    });
};

const fetchUsersUnstakedInProgress = () => {
    return {
        type: FETCH_USERS_UNSTAKED_IN_PROGRESS,
    };
};

const fetchUsersUnstakedSuccess = (value) => {
    return {
        type: FETCH_USERS_UNSTAKED_SUCCESS,
        users: value.users,
        count: value.count,
    };
};

const fetchUsersUnstakedError = (message) => {
    return {
        type: FETCH_USERS_UNSTAKED_ERROR,
        message,
    };
};

export const fetchUsersUnstaked = (skip, limit, sortBy, order, search = '') => (dispatch) => {
    dispatch(fetchUsersUnstakedInProgress());

    const URL = getUsersUnstakedRequestURL(skip, limit, sortBy, order, search);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    }).then((res) => {
        dispatch(fetchUsersUnstakedSuccess(res.data && res.data.result));
    }).catch((error) => {
        dispatch(fetchUsersUnstakedError(
            error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : 'Failed to fetch!',
        ));
    });
};
