import React from 'react';
import * as PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './index.css';

const DatePickerField = (props) => {
    const {
        value,
        onChange,
        label,
        required,
        placeholderText,
    } = props;

    return (
        <>
            {
                label ? (
                    <label className="text-white mb-4 font-semibold block">{label}{required && <span className="text-custom-required">&nbsp;*</span>}</label>
                ) : (
                    <label className="text-white text-sm mb-4 font-semibold block">Date{required && <span className="text-custom-required">&nbsp;*</span>}</label>
                )
            }
            <DatePicker
                className="date-picker rounded-md bg-custom-dark-2 font-oswald-text font-semibold w-full"
                placeholderText={placeholderText}
                selected={value}
                onChange={(date) => onChange(date)}
            />
        </>
    );
};

DatePickerField.propTypes = {
    value: PropTypes.instanceOf(Date).isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    placeholderText: PropTypes.string,
    required: PropTypes.bool,
};

export default DatePickerField;
