import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import { Button } from '@material-ui/core';
import DataTable from '../../components/DataTable';
import { fetchNextVault } from '../../actions/nextVault';
import { NEXT_VAULT_COLUMNS, NEXT_VAULT_TABLE_OPTIONS } from '../../utils/variables/nextVault';
import { DEFAULT_SKIP } from '../../constants/url';
import NextVaultModal from './NextVaultModal';

import './index.css';

const NextVaultTable = (props) => {
    const history = useHistory();
    const {
        fetch,
        list,
        count,
    } = props;
    const [sortBy, setSortBy] = useState('contribution_start');
    const [order, setOrder] = useState('desc');
    const [search, setSearch] = useState('');
    const [data, setData] = useState(list);
    const [openModal, setOpenModal] = useState(false);

    const redirectDetailPage = (id) => {
        history.push(`/next-vault/${id}`);
    };

    const columns = [...NEXT_VAULT_COLUMNS, {
        name: '_id',
        label: 'Actions',
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value) => {
                return (
                    <Button className="view_details_button" onClick={() => redirectDetailPage(value)}>
                        NEXT vault details
                    </Button>
                );
            },
        },
    }];

    const onModalClose = () => {
        setOpenModal(false);
        fetch(DEFAULT_SKIP, 50, sortBy, order);
    };

    const onModalOpen = () => {
        setOpenModal(true);
    };

    // const options = { ...NEXT_VAULT_TABLE_OPTIONS, count };
    const options = {
        ...NEXT_VAULT_TABLE_OPTIONS,
        count,
        customToolbar: () => {
            return (
                <Button className="new_deal" onClick={onModalOpen}>
                    New NEXT vault
                </Button>
            );
        },
        onChangePage: (currentPage) => {
            fetch(currentPage * 50, 50, sortBy, order, search);
        },
        onSearchChange: (searchText) => {
            setSearch(searchText);
            fetch(0, 50, sortBy, order, searchText);
        },
        onColumnSortChange: (changedColumn, direction) => {
            setSortBy(changedColumn);
            setOrder(direction);
            fetch(0, 50, changedColumn, direction, search);
        },
    };

    useEffect(() => {
        fetch(DEFAULT_SKIP, 50, sortBy, order);
    }, []);

    useEffect(() => {
        if (list) {
            setData(list.map((item) => ({
                ...item,
                nav: item.nav || 0,
                contribution_start: moment(item.contribution_start).format('DD MMM YYYY'),
                contribution_end: moment(item.contribution_end).format('DD MMM YYYY'),
                maturity_date: moment(item.maturity_date).format('DD MMM YYYY'),
                action: item._id,
            })));
        }
    }, [list]);

    return (
        <div className="table w-full bg-custom-dark px-2 py-8 md:p-8 rounded-md">
            <DataTable
                columns={columns}
                data={data}
                name="NEXT Vault"
                options={options}
            />
            <NextVaultModal open={openModal} onClose={onModalClose} />
        </div>
    );
};

NextVaultTable.propTypes = {
    count: PropTypes.number.isRequired,
    fetch: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
};

const stateToProps = (state) => {
    return {
        list: state.nextVault.nextVaultList.list,
        count: state.nextVault.nextVaultList.count,
    };
};

const actionToProps = {
    fetch: fetchNextVault,
};

export default connect(stateToProps, actionToProps)(NextVaultTable);
