import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import './index.css';

const TierLimitField = (props) => {
    const {
        value,
        onChange,
    } = props;

    const onFieldChange = (e) => {
        onChange(e.target.value);
    };

    return (
        <>
            <label className="text-white text-sm mb-4 font-semibold block">Tier Limit</label>
            <TextField
                select
                className=" tier-limit rounded-md bg-custom-dark-2 w-full font-oswald-text text-white font-semibold"
                margin="none"
                placeholder="Tier Limit"
                value={value}
                onChange={onFieldChange}>
                <MenuItem value=""></MenuItem>
                <MenuItem value="CORE TIER">CORE TIER</MenuItem>
                <MenuItem value="NEXT TIER">NEXT TIER</MenuItem>
                <MenuItem value="N/A">N/A</MenuItem>
            </TextField>
        </>
    );
};

TierLimitField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default TierLimitField;
