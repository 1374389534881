const URL = process.env.REACT_APP_API_URL;

export const DEFAULT_SKIP = 0;
export const DEFAULT_LIMIT = 10;
export const DEFAULT_COUNT = 10;
export const DEFAULT_SEARCH_KEY = '';
export const DEFAULT_SORT_BY = '';
export const DEFAULT_ORDER = null;

export const FETCH_USER_WITH_STAKED_URL = `${URL}/users/with-staked`;
export const USER_SIGN_IN = `${URL}/admin/login`;
export const USER_SIGN_OUT_URL = `${URL}/admin/me/logout`;
export const PROFILE_DETAILS_FETCH = `${URL}/admin/me/profile/details`;
export const PROFILE_IMAGE_FETCH = `${URL}/admin/me/profile/image`;
export const BANNER_IMAGE_FETCH = `${URL}/admin/me/profile/banner-image`;
export const RECOVERY_EMAIL_URL = `${URL}/admin/me/profile/details/password/forgot`;
export const RECOVERY_PASSWORD_URL = `${URL}/admin/me/profile/details/password/reset`;
export const ACCESS_TOKEN_FETCH = `${URL}/admin/me/access-token`;

export const TWO_FACTOR_AUTH_ADD = `${URL}/admin/me/two-factor-auth`;
export const TWO_FACTOR_AUTH_VERIFY = `${URL}/admin/me/two-factor-auth/verify-token`;
export const TWO_FACTOR_AUTH_VERIFY_BACKUP_TOKEN = `${URL}/admin/me/two-factor-auth/verify-backup-token`;
export const TWO_FACTOR_AUTH_ACTIVATE = `${URL}/admin/me/two-factor-auth/activate`;

export const UN_STAKE_EXPORT_URL = `${URL}/admin/stakings/reports/unstake-requests?exportTo=xlsx`;
export const EXNG_CLAIMS_EXPORT_URL = `${URL}/admin/stakings/reports/claims?exportTo=xlsx`;
export const USERS_EXPORT_URL = `${URL}/users?exportTo=xlsx`;
export const CARDS_INFO_URL = `${URL}/admin/stakings/cards`;
export const MY_NOTIFICATIONS = `${URL}/admin/notifications`;

export const NEW_DEAL_URL = `${URL}/admin/deals`;

export const TIPPING_LIST_URL = `${URL}/admin/tips`;
export const REFERRALS_LIST_URL = `${URL}/users/referrals`;
export const STAKING_PLANS_URL = `${URL}/admin/staking-plans`;
export const CONFIG_URL = `${URL}/admin/config`;

export const TASK_URL = `${URL}/admin/tasks`;
export const CREATE_FORUM_URL = `${URL}/forums`;
export const CREATE_COMMENT_URL = `${URL}/comment/create`;

export const NEXT_VAULT_URL = `${URL}/admin/next-vault`;
export const NEXT_VAULT_LOG_URL = `${URL}/admin/vault-logs`;

export const GET_MESSAGING_USERS = `${URL}/message/users`;

export const specificUserInfo = (ID) => `${URL}/users/${ID}`;
export const specificUserimage = (ID) => `${URL}/users/${ID}/image`;
export const specificUserBanner = (ID) => `${URL}/users/${ID}/banner-image`;
export const singleDealURL = (ID) => `${NEW_DEAL_URL}/${ID}`;
export const singleContributorURL = (ID, contributorID) => `${singleDealURL(ID)}/contributors/${contributorID}`;
export const getContributorAccountURL = (ID) => `${URL}/admin/contributor/user/${ID}/account`;
export const update2FA = (type) => `${URL}/admin/me/two-factor-auth/${type}`;
export const userTipsUrl = (ID) => `${URL}/user/${ID}/tips`;
export const specificTask = (ID) => `${URL}/admin/tasks/${ID}`;
export const specificStatusTask = (ID) => `${URL}/admin/tasks/${ID}/status`;
export const specificArchiveTask = (ID) => `${URL}/admin/tasks/${ID}/archive`;
export const proofTask = (ID) => `${URL}/admin/tasks/${ID}/proof`;

export const specificParticipantTask = (ID, PID) => `${URL}/admin/tasks/${ID}/participant/${PID}`;
export const distributionURL = (ID) => `${singleDealURL(ID)}/distribution`;
export const updateDistributionURL = (ID, distributionID) => `${distributionURL(ID)}/${distributionID}`;
export const updateDistributionItemURL = (ID, distributionID, distributionItemID) => `${distributionURL(ID)}/${distributionID}/item/${distributionItemID}`;
export const getForum = (ID) => `${URL}/forums/${ID}`;
export const deleteForumURL = (ID) => `${URL}/admin/forum/${ID}`;
export const deleteComment = (ID) => `${URL}/admin/comment/${ID}`;
export const getMessages = (ID) => `${URL}/admin/messages/${ID}`;
export const getMessageById = (ID) => `${URL}/admin/message/${ID}`;
export const getSender = (ID) => `${URL}/message/sender/${ID}`;
export const getRecipient = (ID) => `${URL}/admin/message/recipient/${ID}`;
export const getNextVaultDetail = (ID) => `${URL}/admin/next-vault/${ID}`;
export const getNextVaultLog = (ID, skip, limit, sortBy, order, search = '') => {
    const params = [
        `skip=${skip}`,
        `limit=${limit}`,
    ];

    if (sortBy) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }

    if (search) {
        params.push(`search=${search}`);
    }

    return `${URL}/admin/next-vault/${ID}/logs?${params.join('&')}`;
};
export const updateNextVaultLogURL = (ID) => `${URL}/admin/vault-logs/${ID}`;

export const unstakeReportURL = (skip, limit, searchKey, sortBy, order) => {
    const params = [
        `skip=${skip}`,
        `limit=${limit}`,
    ];

    if (searchKey && searchKey.length > 0) {
        params.push(`searchKey=${searchKey}`);
    }

    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }

    return `${URL}/admin/stakings/reports/unstake-requests?${params.join('&')}`;
};

export const getUsersByTierURL = (skip, limit, topTier, sortBy, order, search = '') => {
    const params = [
        `skip=${skip}`,
        `limit=${limit}`,
    ];

    if (topTier) {
        params.push(`topTier=${topTier}`);
    }

    if (sortBy) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }

    if (search) {
        params.push(`search=${search}`);
    }

    return `${URL}/users/tier?${params.join('&')}`;
};

export const getUsersTierURL = (skip, limit, sortBy, order, search = '') => {
    const params = [
        `skip=${skip}`,
        `limit=${limit}`,
    ];

    if (sortBy) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }

    if (search) {
        params.push(`search=${search}`);
    }

    return `${URL}/users/tier?${params.join('&')}`;
};

export const getUsersUnstakedRequestURL = (skip, limit, sortBy, order, search = '') => {
    const params = [
        `skip=${skip}`,
        `limit=${limit}`,
    ];

    if (sortBy) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }

    if (search) {
        params.push(`search=${search}`);
    }

    return `${URL}/users/unstaked-requested?${params.join('&')}`;
};

export const getNextVaultURL = (skip, limit, sortBy, order, search = '') => {
    const params = [
        `skip=${skip}`,
        `limit=${limit}`,
    ];

    if (sortBy) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }

    if (search) {
        params.push(`search=${search}`);
    }

    return `${URL}/admin/next-vault?${params.join('&')}`;
};

export const EXNGClaimsURL = (skip, limit, searchKey, sortBy, order) => {
    const params = [
        `skip=${skip}`,
        `limit=${limit}`,
    ];

    if (searchKey && searchKey.length > 0) {
        params.push(`searchKey=${searchKey}`);
    }

    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }

    return `${URL}/admin/stakings/reports/claims?${params.join('&')}`;
};

export const usersListURL = (skip, limit, searchKey, sortBy, order) => {
    const params = [
        `skip=${skip}`,
        `limit=${limit}`,
    ];

    if (searchKey && searchKey.length > 0) {
        params.push(`searchKey=${searchKey}`);
    }

    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }

    return `${URL}/users?${params.join('&')}`;
};

export const dealsListURL = (skip, limit, searchKey, sortBy, order) => {
    const params = [
        `skip=${skip}`,
        `limit=${limit}`,
    ];

    if (searchKey && searchKey.length > 0) {
        params.push(`searchKey=${searchKey}`);
    }

    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }

    return `${NEW_DEAL_URL}?${params.join('&')}`;
};

export const contributorsListURL = (dealId, skip, limit, searchKey, sortBy, order) => {
    const params = [
        `skip=${skip}`,
        `limit=${limit}`,
    ];

    if (searchKey && searchKey.length > 0) {
        params.push(`searchKey=${searchKey}`);
    }

    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }

    return `${singleDealURL(dealId)}/contributors?${params.join('&')}`;
};

export const commentsURL = (ID, pageNo, limit) => {
    const params = [];
    const COMMENTS_URL = `${URL}/forum/${ID}/comments`;
    if (pageNo) {
        params.push(`page=${pageNo}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    return `${COMMENTS_URL}?${params.join('&')}`;
};

export const forumsURL = (page) => {
    const params = [];
    const FORUMS_URL = `${URL}/forums`;

    if (page) {
        params.push(`page=${page}`);
    }

    return `${FORUMS_URL}?${params.join('&')}`;
};
