import React from 'react';
import './index.css';
import DownloadStakedUsers from './DownloadStakedUsers';
import UserStakeTable from './UserStakeTable';
import UserUnstakeTable from './UserUnstakeTable';

const Dashboard = () => {
    return (
        <div className="dashboard content_padding">
            {/* <Cards/> */}
            <DownloadStakedUsers />
            <UserUnstakeTable pagination />
            <UserStakeTable pagination />
        </div>
    );
};

export default Dashboard;
