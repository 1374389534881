import React from 'react';
import NextVaultTable from './NextVaultTable';

const NextVault = () => {
    return (
        <div className="next-vault content_padding">
            <NextVaultTable />
        </div>
    );
};

export default NextVault;
