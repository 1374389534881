import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import CloseDialog from '../../components/CloseDialog';
import TextField from '../../components/TextField';
import DatePickerField from '../../components/DatePickerField';
import MaturityDateField from '../../components/MaturityDateField';
import Button from '../../components/Button';
import Snackbar from '../../components/Snackbar';
import { connect } from 'react-redux';
import { NEXT_VAULT_DEFAULT_VALUE } from '../../utils/variables/nextVault';
import { addNextVault, updateNextVault } from '../../actions/nextVault';

const NextVaultModal = (props) => {
    const {
        onClose,
        open,
        addNextVault,
        updateNextVault,
        details,
    } = props;
    const [nextVault, setNextVault] = useState(NEXT_VAULT_DEFAULT_VALUE);
    const [snackbar, setSnackbar] = useState({
        message: '',
        open: false,
        severity: 'error',
    });

    useEffect(() => {
        if (details) {
            setNextVault((v) => ({
                ...v,
                id: details._id,
                name: details.name,
                description: details.description,
                nav: details.nav,
                contribution_start: new Date(details.contribution_start),
                contribution_end: new Date(details.contribution_end),
                maturity_date: new Date(details.maturity_date).getFullYear() - new Date(details.contribution_end).getFullYear(),
            }));
        }
    }, [details]);

    const openSnackbarOnError = (message) => {
        setSnackbar((v) => ({ ...v, open: true, message, severity: 'error' }));
    };

    const openSnackbarOnSuccess = (message) => {
        setSnackbar((v) => ({ ...v, open: true, message, severity: 'success' }));
    };

    const resetNextVault = () => {
        setNextVault(NEXT_VAULT_DEFAULT_VALUE);
    };

    const checkFormValidation = () => {
        const {
            name,
            description,
            contribution_start: contributionStart,
            contribution_end: contributionEnd,
            maturity_date: maturityDate,
        } = nextVault;
        if (!name) {
            openSnackbarOnError('Name is required');
            return false;
        }

        if (!description) {
            openSnackbarOnError('Description is required');
            return false;
        }

        if (!contributionStart) {
            openSnackbarOnError('Contribution start is required');
            return false;
        }

        if (!contributionEnd) {
            openSnackbarOnError('Contribution end is required');
            return false;
        }

        if (!maturityDate) {
            openSnackbarOnError('Maturity date is required');
            return false;
        }

        return true;
    };

    const submitForm = () => {
        const isFormValid = checkFormValidation();

        if (isFormValid) {
            if (nextVault.id) {
                updateNextVault(nextVault, (error, result) => {
                    if (result) {
                        openSnackbarOnSuccess('Successfully updated next vault');
                        onClose();
                    } else if (error) {
                        openSnackbarOnError(`${error}`);
                    }
                });
            } else {
                addNextVault(nextVault, (error, result) => {
                    if (result) {
                        openSnackbarOnSuccess('Successfully added next vault');
                        onClose();
                    } else if (error) {
                        openSnackbarOnError(`${error}`);
                    }
                });
            }
        }
    };

    const handleSnackbarClose = () => {
        setSnackbar((v) => ({ ...v, open: false }));
    };

    const handleDialogClose = () => {
        onClose();
        resetNextVault();
    };

    return (
        <>
            <Snackbar
                message={snackbar.message}
                open={snackbar.open}
                severity={snackbar.severity}
                onClose={handleSnackbarClose}
            />
            <Dialog
                aria-describedby="next-vault-dialog-description"
                aria-labelledby="next-vault-dialog-title"
                className="dialog next-vault"
                maxWidth="lg"
                open={open}
                onClose={handleDialogClose}
            >
                <DialogContent className="content relative text-white">
                    <p className="font-oswald-text font-bold text-2xl">Next Vault</p>
                    <CloseDialog className="absolute top-4 md:top-10 right-6 md:right-14" onClose={handleDialogClose}/>
                    <form
                        noValidate
                        autoComplete="off"
                        className="form flex flex-col md:flex-row mt-6"
                    >
                        <div className="w-full">
                            <div className="flex flex-col mb-4 font-oswald-text">
                                <p className="block text-base font-semibold">
                                    <span className="text-white ">
                                        Name
                                    </span>
                                    <span className="text-white">&nbsp;*</span>
                                </p>
                                <TextField
                                    id="next_vault_name"
                                    name="name"
                                    placeholder="Enter name"
                                    value={nextVault.name}
                                    onChange={(e) => setNextVault((v) => ({ ...v, name: e }))}
                                />
                            </div>

                            <div className="flex flex-col mb-4 font-oswald-text">
                                <p className="block text-base font-semibold">
                                    <span className="text-white ">
                                        Description
                                    </span>
                                    <span className="text-white">&nbsp;*</span>
                                </p>
                                <TextField
                                    id="next_vault_description"
                                    multiline={true}
                                    name="description"
                                    placeholder="Enter your description"
                                    value={nextVault.description}
                                    onChange={(e) => setNextVault((v) => ({ ...v, description: e }))}
                                />
                            </div>
                            <div className="flex flex-col mb-4 font-oswald-text">
                                <p className="block text-base font-semibold">
                                    <span className="text-white ">
                                        Nav
                                    </span>
                                </p>
                                <TextField
                                    id="next_vault_nav"
                                    name="nav"
                                    placeholder="Enter nav"
                                    type="number"
                                    value={nextVault.nav}
                                    onChange={(e) => setNextVault((v) => ({ ...v, nav: e }))}
                                />
                            </div>
                            <div className="flex flex-col mb-4 font-oswald-text">
                                <DatePickerField label="Contribution Start Date" required={true} value={nextVault.contribution_start} onChange={(e) => setNextVault((v) => ({ ...v, contribution_start: e }))} />
                            </div>
                            <div className="flex flex-col mb-4 font-oswald-text">
                                <DatePickerField label="Contribution End Date" required={true} value={nextVault.contribution_end} onChange={(e) => setNextVault((v) => ({ ...v, contribution_end: e }))} />
                            </div>
                            <div className="flex flex-col font-oswald-text">
                                <p className="block text-base font-semibold">
                                    <span className="text-white ">
                                        Maturity Date
                                    </span>
                                    <span className="text-white">&nbsp;*</span>
                                </p>
                                <div className="mt-4">
                                    <MaturityDateField value={nextVault.maturity_date} onChange={(e) => setNextVault((v) => ({ ...v, maturity_date: e }))} />
                                </div>
                            </div>
                        </div>
                    </form>
                </DialogContent>
                <DialogActions className="actions">
                    <div className="modal-footer">
                        <Button className="mx-2 w-full font-oswald-text" color="primary" size="lg" onClick={submitForm}>
                            <span>Submit</span>
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
};

NextVaultModal.propTypes = {
    addNextVault: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    updateNextVault: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    details: PropTypes.object,
};

const stateToProps = (state) => {
    return {
    };
};

const actionToProps = {
    addNextVault,
    updateNextVault,
};

export default connect(stateToProps, actionToProps)(NextVaultModal);
