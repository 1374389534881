export const NEXT_VAULT_COLUMNS = [
    {
        name: 'name',
        label: 'Name',
        options: {
            filter: false,
            sort: true,
            sortOrder: 'asc',
        },
    }, {
        name: 'nav',
        label: 'Nav',
        options: {
            filter: false,
            sort: true,
            sortOrder: 'asc',
        },
    }, {
        name: 'contribution_start',
        label: 'Contributor Start Date',
        options: {
            filter: false,
            sort: true,
            sortOrder: 'asc',
        },
    }, {
        name: 'contribution_end',
        label: 'Contributor End Date',
        options: {
            filter: false,
            sort: true,
            sortOrder: 'asc',
        },
    }, {
        name: 'maturity_date',
        label: 'Maturity Date',
        options: {
            filter: false,
            sort: true,
            sortOrder: 'asc',
        },
    },
];

export const NEXT_VAULT_LOG_COLUMNS = [
    {
        name: 'author',
        label: 'Name',
        options: {
            filter: false,
            sort: true,
            sortOrder: 'asc',
        },
    }, {
        name: 'date',
        label: 'Date',
        options: {
            filter: false,
            sort: true,
            sortOrder: 'asc',
        },
    }, {
        name: 'amount',
        label: 'Amount',
        options: {
            filter: false,
            sort: true,
            sortOrder: 'asc',
        },
    }, {
        name: 'transaction_link',
        label: 'Transaction Link',
        options: {
            filter: false,
            sort: false,
        },
    },
];

export const NEXT_VAULT_TABLE_OPTIONS = {
    responsive: 'vertical',
    serverSide: true,
    print: false,
    pagination: true,
    count: 10,
    download: false,
    selectableRows: 'none',
    rowsPerPage: 50,
};

export const NEXT_VAULT_DEFAULT_VALUE = {
    name: '',
    description: '',
    nav: 0,
    contribution_start: new Date(),
    contribution_end: new Date(),
    maturity_date: 0,
};

export const NEXT_VAULT_LOG_DEFAULT_VALUE = {
    date: new Date(),
    amount: '',
    transaction_link: '',
};
