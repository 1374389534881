import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import DataTable from '../../components/DataTable';
import Snackbar from '../../components/Snackbar';
import { fetchDeals } from '../../actions/deals';
import { fetchConfig } from '../../actions/config';
import variables from '../../utils/variables';
import { DEFAULT_LIMIT, DEFAULT_ORDER, DEFAULT_SEARCH_KEY, DEFAULT_SKIP, DEFAULT_SORT_BY } from '../../constants/url';
import DealModal from './DealModal';
import './index.css';
import { Link } from 'react-router-dom';
import { fetchProfileDetails } from '../../actions/navigationBar';

class DealsTable extends Component {
    constructor (props) {
        super(props);

        this.state = {
            openForm: false,
            snackbarMessage: '',
            snackbarOpen: false,
        };

        this.fetchDefault = this.fetchDefault.bind(this);
        this.handleFetch = this.handleFetch.bind(this);
        this.handleViewDetails = this.handleViewDetails.bind(this);
        this.handleShowDealForm = this.handleShowDealForm.bind(this);
        this.handleCloseDealForm = this.handleCloseDealForm.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
    }

    componentDidMount () {
        const { fetchConfig } = this.props;
        this.fetchDefault();
        fetchConfig();
    }

    fetchDefault () {
        this.handleFetch(DEFAULT_SKIP,
            DEFAULT_LIMIT,
            DEFAULT_SEARCH_KEY,
            DEFAULT_SORT_BY,
            DEFAULT_ORDER);
    }

    handleFetch (skip, limit, searchText, sortBy, order) {
        const { fetch } = this.props;
        fetch(skip, limit, searchText, sortBy, order);
    }

    handleViewDetails (value) {
        const { history } = this.props;

        history.push(`/deals/${value}`);
    }

    handleShowDealForm () {
        this.setState({ openForm: true });
    }

    handleCloseDealForm () {
        this.setState({ openForm: false });
    }

    onSuccess (message) {
        console.log(message);
        this.setState({
            snackbarMessage: message,
            snackbarOpen: true,
        });
    }

    handleSnackbarClose () {
        this.setState({ snackbarOpen: false });
    }

    render () {
        const {
            count,
            data,
            lang,
            dealConfig,
        } = this.props;

        const {
            openForm,
            snackbarMessage,
            snackbarOpen,
        } = this.state;

        const options = {
            responsive: 'vertical',
            serverSide: true,
            print: false,
            count,
            selectableRows: 'none',
            customToolbar: () => {
                return (
                    <Button className="new_deal" onClick={this.handleShowDealForm}>
                        {variables[lang].new_deal}
                    </Button>
                );
            },
            onSearchChange: (searchText) => {
                const { skip, limit, sortBy } = this.props;
                this.handleFetch(skip, limit, searchText, sortBy, null);
            },
            onChangePage: (currentPage) => {
                const { limit, sortBy, data, searchKey } = this.props;

                if (data && data.length === 0) {
                    return;
                }

                this.handleFetch(limit * currentPage, limit, searchKey, sortBy, null);
            },
            onChangeRowsPerPage: (numberOfRows) => {
                const { skip, sortBy, data, searchKey } = this.props;

                if (data && data.length === 0) {
                    return;
                }

                this.handleFetch(skip, numberOfRows, searchKey, sortBy, null);
            },
            onColumnSortChange: (changedColumn, direction) => {
                const { skip, limit, data, searchKey } = this.props;

                if (data && data.length === 0) {
                    return;
                }

                const order = direction === 'asc' ? 'asc' : 'desc';
                this.handleFetch(skip, limit, searchKey, changedColumn, order);
            },
        };

        const columns = [
            {
                name: 'name',
                label: 'Name',
                options: {
                    filter: false,
                    sort: true,
                },
            },
            {
                name: 'round',
                label: 'Round',
                options: {
                    filter: false,
                    sort: true,
                },
            },
            {
                name: 'total_allocation',
                label: 'Total Allocation',
                options: {
                    filter: false,
                    sort: true,
                },
            },
            {
                name: 'owner',
                label: 'Owner',
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value) => (
                        <Link className="font-bold user-detail__link" to={`/${(this.props.user_id === value._id) ? 'profile' : `user/${value._id}`}`}>{value.email_address}</Link>
                    ),
                },
            },
            {
                name: 'status',
                label: 'Status',
                option: {
                    filter: true,
                    sort: false,
                },
            },
            {
                name: 'action',
                label: variables[lang].actions,
                options: {
                    filter: false,
                    sort: false,
                    // eslint-disable-next-line react/display-name
                    customBodyRender: (value) => (
                        <Button
                            className="view_details_button"
                            onClick={() => this.handleViewDetails(value)}
                        >
                            {variables[lang]['view_details']}
                        </Button>
                    ),
                },
            },
        ];

        const tableData = data && data.length
            ? data.map((item) =>
                [
                    item.name,
                    item.round,
                    item.total_allocation ? item.total_allocation.toLocaleString('en-US') : 0,
                    item.owner,
                    item.is_archive === true ? 'ARCHIVED' : item.status,
                    item._id,
                ])
            : [];

        return (
            <div className="table w-full bg-custom-dark px-2 py-8 md:p-8 rounded-md">
                <DataTable
                    columns={columns}
                    data={tableData}
                    name="Deals"
                    options={options}/>
                <DealModal
                    dealConfig={dealConfig}
                    fetchDefault={this.fetchDefault}
                    open={openForm}
                    onClose={this.handleCloseDealForm}
                    onSuccess={this.onSuccess}
                />
                <Snackbar
                    message={snackbarMessage}
                    open={snackbarOpen}
                    severity="success"
                    onClose={this.handleSnackbarClose}
                />
            </div>
        );
    }
}

DealsTable.propTypes = {
    count: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
    dealConfig: PropTypes.object.isRequired,
    fetch: PropTypes.func.isRequired,
    fetchConfig: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    lang: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    searchKey: PropTypes.string.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    user_id: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        user_id: state.navigationBar.profileDetails.value._id,
        auth: state.accessToken.value,
        data: state.deals.listDeals.list,
        count: state.deals.listDeals.count,
        inProgress: state.deals.listDeals.inProgress,
        lang: state.language,
        limit: state.deals.listDeals.limit,
        order: state.deals.listDeals.order,
        searchKey: state.deals.listDeals.searchKey,
        skip: state.deals.listDeals.skip,
        sortBy: state.deals.listDeals.sortBy,
        dealConfig: state.config.dealConfig.result,
    };
};

const actionToProps = {
    fetch: fetchDeals,
    fetchConfig,
    fetchProfileDetails,
};

export default withRouter(connect(stateToProps, actionToProps)(DealsTable));
